import React from 'react'
import service4 from '../../assets/images/services/RVservice1.jpg'


const Services = () => {
    return (
        <section className="overview-area pt-70 pb-70">
            <div className="container">
                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={service4} alt="features" className="case-studies-details-info" />
                        </div>
                    </div>
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title"></span>
                            <span className="sub-title"></span>
                            <h2>Le cabinet RV Expertises, des Experts au service de l’assuré. <br />Nous défendons vos intérêts.</h2>
                            <p>Le cabinet RV Expertises est un cabinet d’experts indépendant du bâtiment &amp; de la construction.</p>
                            <p>Experts construction indépendants, nous proposons des solutions expertales personnalisées et adaptées aux besoins du particulier, des entreprises et des assurés sinistrés.</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Un sinistre sécheresse non garanti par votre assurance ?</h4>
                                <h4><i className="flaticon-tick"></i> Des malfaçons constatées sur votre chantier ?</h4>
                                <h4><i className="flaticon-tick"></i> Besoin d’un avis technique avant l’achat ou la vente d’un bien ?</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}
            </div>
        </section>
    )
}

export default Services

/*

<p>L’Expert d’assuré n’est pas un Expert d’assurance. L’Expert d’assuré intervient à la demande de l’Assuré pour défendre ses intérêts.</p>
<p>Le recours à un Expert Construction est souvent nécessaire lorsque un particulier, une entreprise ou une collectivité est confronté à une problématique de gestion de sinistre, des pathologies du bâtiment ou autres besoins liés au monde l'expertise.</p>
<p>L’Expert d’assuré est votre représentant lors des négociations avec l’assurance jusqu’à la juste indemnisation de votre sinistre.</p>
<p>Pourquoi faire appel à un Expert Bâtiment ?</p>


<h4><i className="flaticon-tick"></i> Pourquoi faire appel à un Expert Bâtiment ?</h4>
<h4><i className="flaticon-tick"></i> Vous constatez des désordres et malfaçons sur votre ouvrage ?</h4>
<h4><i className="flaticon-tick"></i> Vous avez besoin de conseils et d'être orienté dans vos démarches ?</h4>
<h4><i className="flaticon-tick"></i> Vous avez subi un sinistre et votre assurance ne vous indemnise pas à la juste valeur de vos dommages, voire ne vous indemnise pas ?</h4>
<h4><i className="flaticon-tick"></i> Une réception de chantier qui se passe mal ?</h4>
<h4><i className="flaticon-tick"></i> Un besoin d’assistance technique en expertise judiciaire ?</h4>

<p>Notre équipe est là pour vous aider et répondre à vos problématiques de gestion de sinistres !</p>
<p>Contacter RV EXPERTISES</p>

*/