import React from 'react'
import icon1 from '../../assets/images/funfacts/competence.png'
import icon2 from '../../assets/images/funfacts/independance.png'
import icon3 from '../../assets/images/funfacts/disponibilite.png'
import icon4 from '../../assets/images/funfacts/vente.png'

const FunFacts = () => {
    return (
        <section className="funfacts-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon1} alt="features" />
                            </div>
                            <h3><br />Compétence<br /></h3>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon2} alt="features" />
                            </div>
                            <h3><br />Indépendance<br /></h3>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon3} alt="features" />
                            </div>
                            <h3><br />Disponibilité<br /></h3>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon4} alt="features" />
                            </div>
                            <h3>+ de 400 clients satisfaits</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FunFacts