import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/NavbarRV"
import Footer from "../components/App/FooterRV"
import MainBanner from "../components/RVExpertises/MainBanner"
import Services1 from "../components/RVExpertises/Services1"
import Services2 from "../components/RVExpertises/Services2"
import FunFacts from "../components/RVExpertises/FunFacts"

const Index = () => {
    return (
        <Layout>
            <Navbar />
            <MainBanner />
            <Services1 />
            <Services2 />
            <FunFacts />
            <Footer />
        </Layout>
    );
}

export default Index
