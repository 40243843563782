import React from 'react'
import service5 from '../../assets/images/services/RVservice2.jpg'
import service6 from '../../assets/images/services/RVservice3.jpg'

const Services = () => {
    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                {/* Right Image Style */}
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">Notre savoir faire</span>
                            <h2>Nos prestations :</h2>
                            <p></p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Expertise fissure et sécheresse</h4>
                                <h4><i className="flaticon-tick"></i> Expertise humidité, dégâts des eaux &amp; inondation</h4>
                                <h4><i className="flaticon-tick"></i> Assistance à réception d’ouvrage</h4>
                                <h4><i className="flaticon-tick"></i> Expertise avant acquisition immobilière et avant vente</h4>
                                <h4><i className="flaticon-tick"></i> Expertises malfaçons</h4>
                                <h4><i className="flaticon-tick"></i> Expertise d’assuré &amp; assistance en expertise judiciaire</h4>
                                <h4><i className="flaticon-tick"></i> Litige assurance - recours amiable - médiation &amp; conciliation</h4>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={service5} alt="features" className="case-studies-details-info" />
                        </div>
                    </div>
                </div>
                {/* End Right Image Style */}

                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={service6} alt="features" className="case-studies-details-info" />
                        </div>
                    </div>
                    
                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">Nos zones géographiques</span>
                            <h2>Cabinet RV EXPERTISES</h2>
                            <p><a href="/paca/">Trouvez un expert près de chez vous.</a></p>

                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Bouches du Rhone (13)</span></li>
                                <li><span><i className='bx bx-check'></i> Var (83</span></li>
                                <li><span><i className='bx bx-check'></i> Vaucluse (84)</span></li>
                                <li><span><i className='bx bx-check'></i> Alpes maritimes (06)</span></li>
                                <li><span><i className='bx bx-check'></i> Gard (30)</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}
            </div>
        </section>
    )
}

export default Services